<template>
    <div id="willOverview">
        <div class="vx-row">
            <div
                class="vx-col w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 mb-base"
            >
                <select
                    v-model="form.years"
                    class="select"
                    name="pets"
                    multiple
                    size="5"
                >
                    <option
                        v-for="(item, index) in yearOptions"
                        :key="index"
                        :value="item"
                    >
                        {{ item }}
                    </option>
                </select>

                <vs-button @click="reportDataFetch">Fetch report</vs-button>

                <table
                    v-for="(year, indexYear) in data"
                    :key="`year${indexYear}`"
                    class="mb-10"
                >
                    <tr>
                        <th>{{ year.year }}</th>
                        <th
                            v-for="(month, monthIndexHeader) in year.months"
                            :key="`monthHeader${monthIndexHeader}`"
                        >
                            {{ month.month }}
                        </th>
                    </tr>
                    <tr>
                        <th>Invoices</th>
                        <th
                            v-for="(month, monthIndexInvoices) in year.months"
                            :key="`monthIndexInvoices${monthIndexInvoices}`"
                        >
                            {{ month.data.invoices / 100 }}
                        </th>
                    </tr>
                    <tr>
                        <th>Subscriptions</th>
                        <th
                            v-for="(month,
                            monthIndexSubscription) in year.months"
                            :key="`monthIndexSubscription${monthIndexSubscription}`"
                        >
                            {{ month.data.subscriptions / 100 }}
                        </th>
                    </tr>
                    <tr>
                        <th>Printing</th>
                        <th
                            v-for="(month, monthIndexPrinting) in year.months"
                            :key="`monthIndexPrinting${monthIndexPrinting}`"
                        >
                            {{ month.data.printing / 100 }}
                        </th>
                    </tr>
                    <tr>
                        <th>Wills</th>
                        <th
                            v-for="(month, monthIndexWill) in year.months"
                            :key="`monthIndexWill${monthIndexWill}`"
                        >
                            {{ month.data.wills / 100 }}
                        </th>
                    </tr>
                    <tr bgcolor="#F35557">
                        <th>Gocardless</th>
                        <th
                            v-for="(month, monthIndexGo) in year.months"
                            :key="`monthIndexGo${monthIndexGo}`"
                        >
                            {{ month.data.gocardless_payouts / 100 }}
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'Payments',
    mixins: [textFormat],
    components: {},
    props: [],
    beforeRouteUpdate(to, from, next) {
        next()
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    beforeRouteLeave(to, from, next) {
        next()
    },
    computed: {
        subTitle() {
            return 'Payments by year'
        },
        yearOptions() {
            let years = []
            let startYear = 2018
            let finishYear = new Date().getFullYear()
            let range = finishYear - startYear
            for (let i = 0; i < range + 1; i++) {
                years.push(startYear + i)
            }
            return years
        },
    },
    data() {
        return {
            form: {
                years: [],
            },
            data: null,
            backPath: null,
        }
    },
    methods: {
        reportDataFetch() {
            this.$vs.loading()

            http.get('report_payments_year', {
                params: {
                    years: this.form.years,
                },
            })
                .then((response) => {
                    this.data = response.data
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
    },
}
</script>

<style scoped>
.no-over {
    overflow: visible;
}
select {
    width: 100px;
}
</style>
